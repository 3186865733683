import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { TruckingSettlementReportHeaderDto } from "@/models/interface/trucking-settlement-report";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingSettlementDetailReportService extends HttpClient {
  constructor() {
    super();
  }

  getList(
    params?: RequestQueryParamsModel
  ): Promise<TruckingSettlementReportHeaderDto> {
    return this.get<TruckingSettlementReportHeaderDto>(
      Api.TruckingSettlementDetailReportList,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.TruckingSettlementDetailReportDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingSettlementDetailReportService =
  new TruckingSettlementDetailReportService();
